<template>
    <div class="row pt-1">
        <div class="offset-4 col-8">
            <div class="d-flex align-items-start justify-content-end">
                <div class="flex-grow-0 mr-1 user-message">
                    <p class="p-1 m-0"> {{ data }} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: String,
    }
};
</script>