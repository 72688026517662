<template>
  <div class="row pt-1">
    <div class="col-10 p-0">
      <div class="d-flex align-items-end px-1">
        <img class="rounded-circle user-avatar" :src="require(`@/assets/images/alex/${alexImage}`)" alt="" srcset="">
        <div class="flex-grow-0 ml-1 bot-message">
          <p class="p-1 m-0 ">{{ quizData.question }}</p>
        </div>
      </div>
      <div v-if="!isSelected" class="d-flex align-items-end p-1">
        <span class="rounded-circle user-placeholder"></span>
        <div v-if="!isSelected" class="flex-grow-0 ml-1 list-group">
          <div v-for="(item, key) in quizData.choices" :key="item.id">
            <div class="list-group-cust p-1">
              <div class="form-check">
                <input class="form-check-input" type="radio" :id="item" name="quizSelect" v-model="selectedOption"
                  v-bind:value="{ answer: key, questionData: quizData }" v-on:change="submit">
                <label class="form-check-label label" :for="item">{{ item.title || item.label }} </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSelected: false,
      selectedOption: Object
    }
  },
  methods: {
    submit() {
      this.isSelected = true;
      this.callback({
        answer: this.selectedOption.answer,
        selected: this.selectedOption.selected,
        questionData: this.selectedOption.questionData,
      });
    }
  },
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
  },
  props: {
    quizData: Object,
    callback: Function,
  }
};
</script>
<style>
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--background);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--org-color);
}

input[type='radio']:checked:after {
  top: -2px;
  left: -1px;
  width: 15px;
  height: 15px;
  position: relative;
  visibility: visible;
  border-radius: 15px;
  display: inline-block;
  background-color: var(--org-color);
}

.label {
  cursor: pointer;
  color: var(--text-secondary);
}

.list-group-cust {
  border-width: 1px;
  border-style: solid;
  background-color: var(--background);
  border-color: var(--org-color) !important;
}

.list-group-cust :hover {
  background-color: var(--background);
  border-color: var(--org-color) !important;
}
</style>
