<template>
  <div>
    <div v-for="item in this.conversationData" :key="item.label">
      <BotMessage v-if="!item.user" :data="item" :callback="submitUserResponse" :showImage="showImage(item)" />
      <UserMessage v-if="item.user" :data="item.selectedItems.length != 0 && typeof item.selectedItems === Array
        ? item.selectedItems.map((e) => e.title).join(' | ')
        : item.label
        " />
    </div>
    <LoadingResponse v-if="isResonseLoading" />
  </div>
</template>

<script>
import { QuizConfig } from "./config.js";
import OrgConfig from "@/assets/data/organizationConfig";
import UserMessage from "@/components/messages/UserMessage.vue";
import BotMessage from "@/components/messages/BotMessage.vue";
import LoadingResponse from "@/components/messages/LoadingResponse.vue";
const FormData = require("form-data");

export default {
  data() {
    return {
      answers: [],
      configData: {},
      isResonseLoading: false,
      isUniversalLoading: false,
    };
  },
  components: {
    UserMessage,
    BotMessage,
    LoadingResponse,
  },
  methods: {
    pushConversationData(data) {
      this.$store.commit("quiz/PUSH_CONVERSATION_DATA", data);
    },
    showImage(item) {
      if (item.buttons || item.choices || (!item.buttons && !item.choices && !item.action_id)) {
        return true;
      }
      return false;
    },
    checkNextMessage() {
      var lastMessage = this.conversationData[this.conversationData.length - 1];
      if (lastMessage.action_id) {
        this.pushConversationData(this.configData[lastMessage.action_id]);
        lastMessage = this.conversationData[this.conversationData.length - 1];
        if (lastMessage.action_id) {
          this.checkNextMessage();
        }
      }
    },
    startConversation() {
      this.pushConversationData(this.configData.init);
      this.checkNextMessage();
    },
    submitUserResponse(data) {
      this.isResonseLoading = true;
      this.pushConversationData({ ...data, user: true });
      setTimeout(() => {
        this.pushConversationData(this.configData[data.action_id]);
        this.checkNextMessage();
        if (data.selectedItems.length !== 0) {
          this.answers.push({ file: data.selectedItems });
        }
        this.isResonseLoading = false;
      }, 2000);
    },
    setOrgInfo(orgSlug) {
      var orgDetails = OrgConfig[orgSlug];
      if (!orgDetails) orgDetails = OrgConfig.default;
      this.alexName = orgDetails.alexName;
      this.$store.commit("user/SET_ORGANIZATION_ALEX_IMAGE_PATH", orgDetails.alexImagePath);
      let domStyle = document.documentElement.style;
      domStyle.setProperty("--org-color", orgDetails.orgColor);
      domStyle.setProperty("--button-color", orgDetails.buttons);
      domStyle.setProperty("--background", orgDetails.background);
      domStyle.setProperty("--text-primary", orgDetails.textPrimary);
      domStyle.setProperty("--text-secondary", orgDetails.textSecondary);
      domStyle.setProperty("--user-reply-color", orgDetails.userReplyColor);
      domStyle.setProperty("--bot-reply-color", orgDetails.botReplyColor);
    },
    scrollToElement() {
      const scrollableDiv = this.$refs.scrollableDiv;
      if (scrollableDiv) {
        const scrollHeight = scrollableDiv.scrollHeight;
        const clientHeight = scrollableDiv.clientHeight;
        const maxScrollTop = scrollHeight - clientHeight;

        const scrollStep = 10; // step size as needed
        const scrollInterval = 10; // interval for smoother animation

        let currentScrollTop = scrollableDiv.scrollTop;
        const scrollDown = () => {
          if (currentScrollTop < maxScrollTop) {
            currentScrollTop = Math.min(currentScrollTop + scrollStep, maxScrollTop);
            scrollableDiv.scrollTop = currentScrollTop;
            setTimeout(scrollDown, scrollInterval);
          }
        };
        scrollDown();
      }
    },
  },
  watch: {
    conversationData() {
      var lastMessage = this.conversationData[this.conversationData.length - 1];
      if (!lastMessage.buttons && !lastMessage.action_id) {
        console.log("done======");
        let data = new FormData();
        data.append("document", this.answers[0].file);
        data.append("gtbrddOrganizationId", this.organizationId);
        data.append("gtbrddUserId", this.userId);
        data.append("feedItemId", this.quizId);
        this.$store.dispatch("quiz/submitUploadQuiz", data).catch((err) => console.log(err));
        return;
      }
    },
  },
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
    conversationData() {
      return this.$store.state.quiz.conversationData;
    },
    userId() {
      return this.$store.state.user.userId;
    },
    quizId() {
      return this.$store.state.quiz.quizId;
    },
    email() {
      return this.$store.state.user.email;
    },
    userName() {
      return this.$store.state.user.name;
    },
    timezone() {
      return this.$store.state.user.timezone;
    },
    orgSlug() {
      return this.$store.state.user.orgSlug;
    },
    organizationId() {
      return this.$store.state.user.organizationId;
    },
    courseDetails() {
      return this.$store.state.quiz.courseDetails;
    },
  },
  created() {
    this.$store
      .dispatch("quiz/getFeedItemDetails", { itemId: this.quizId, type: this.$route.query.type })
      .then((res) => {
        this.$store.commit("quiz/SET_COURSE_DETAILS", res);
      })
      .catch((err) => console.log(err)).finally(() => {
        this.configData = QuizConfig(this.userName, this.courseDetails.title);
        this.startConversation();
      })
    this.isUniversalLoading = false;
  },
};
</script>
